import React, { Fragment, useState } from 'react';
import Services from '../../api/service';
import { useParams, Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import WhyUs from '../../main-component/AboutUsPage/WhyUs.js';
import CtaSection from '../../components/CtaSection/CtaSection';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import srImg from '../../images/services/image_program.jpg';
import srImg2 from '../../images/services/web_cat.jpg';
import srImg3 from '../../images/services/web_fisik.jpg';
import srImg4 from '../../images/services/web_konsul.jpg';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton';

const ServiceSinglePage = (props) => {

    const { slug } = useParams();
    const ServiceDetails = Services.find(item => item.slug === slug);

    const [isOpen, setOpen] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    // Define descriptions outside of JSX
    const serviceDescriptions = {
        "Program Persiapan Sekolah Kedinasan": (
            <p>
                Program Persiapan Sekolah Kedinasan adalah program yang diselenggarakan oleh Bimbel DELTA yang bertujuan untuk membantu siswa-siswi yang ingin melanjutkan pendidikan ke sekolah kedinasan. Program ini memberikan pembekalan materi dan simulasi tes yang akan diujikan pada seleksi masuk sekolah kedinasan. Program ini diikuti oleh siswa-siswi yang berminat melanjutkan pendidikan ke sekolah kedinasan seperti STAN, IPDN, dan Sekolah Tinggi Intelijen Negara (STIN).
            </p>
        ),
        "Program Persiapan Universitas Pertahanan": (
            <p>
                Program Persiapan Universitas Pertahanan adalah program yang diselenggarakan oleh Bimbel DELTA yang bertujuan untuk membantu siswa-siswi yang ingin melanjutkan pendidikan ke Universitas Pertahanan...
            </p>
        ),
        "Program Persiapan CPNS": (
            <p>
                Siap untuk mewujudkan impian Anda menjadi Aparatur Sipil Negara (ASN)? Program Persiapan CPNS kami hadir untuk membantu Anda menghadapi seleksi CPNS dengan persiapan yang matang dan strategi yang tepat. Kami memahami betapa pentingnya setiap langkah dalam perjalanan ini, sehingga kami menyediakan program pelatihan intensif yang fokus pada pembekalan Anda untuk lulus ujian dengan percaya diri.
            </p>
        ),
        // Default case if no match
        "default": (
            <p>
                Persiapkan masa depan Anda menuju sekolah kedinasan dengan Program Online Class kami yang fleksibel dan efektif. Dirancang khusus untuk memberikan kemudahan belajar dari mana saja, program ini memungkinkan Anda mengikuti pelajaran tanpa batasan lokasi atau waktu. Dengan modul materi yang komprehensif dan bimbingan langsung dari pengajar berpengalaman, Anda akan mendapatkan semua yang dibutuhkan untuk mempersiapkan ujian seleksi sekolah kedinasan dengan percaya diri.
            </p>
        )
    };

    const serviceDescription = serviceDescriptions[ServiceDetails.title] || serviceDescriptions["default"];

    return (
        <Fragment>
            <Header />
            <main className="page_content service-single-page">
                <PageTitle pageTitle={ServiceDetails.title} />
                <section className="service_details_section section_space bg-light">
                    <div className="container">
                        <div className="details_item_image position-relative">
                            <img src={srImg} alt="Service Details" />
                        </div>
                        <h2 className="details_item_title">{ServiceDetails.title}</h2>

                        {/* Display the corresponding service description */}
                        {serviceDescription}

                        <ProcessTechnology />

                        {/* Image Grid */}
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="details_item_image m-0">
                                    <img src={srImg2} alt="Service Details" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="details_item_image m-0">
                                    <img src={srImg3} alt="Service Details" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="details_item_image m-0">
                                    <img src={srImg4} alt="Service Details" />
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-5"> 
                            <Link
                                to="/pricing" 
                                className="btn btn-primary"
                                onClick={ClickHandler}
                                style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                            >
                                <span className="btn_label" data-text="Daftar Harga">Daftar Harga</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </section>
            </main>
            <WhyUs />
            <CtaSection />
            <WhatsappButton/>
            <Footer />
            <Scrollbar />
            <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    );
};

export default ServiceSinglePage;
