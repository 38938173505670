import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import aImg from '../../images/about/foto_about2.jpg'
import aImg2 from '../../images/about/foto_about.jpg'
import ModalVideo from 'react-modal-video'
import PolicySection from './Policy';
import WhyUs from './WhyUs';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import Tentor from '../../components/Tentor/Tentor';
import CtaSection from '../../components/CtaSection/CtaSection';
import WhatsappButton from '../../components/WhatsappButton/whatsappbutton'


const AboutUsPage = (props) => {

    const [isOpen, setOpen] = useState(false)
    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Tentang DELTA'}/>
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Techco - About " />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="Techco - About" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="heading_block mb-0">
                            <div className="row justify-content-lg-between">
                                <div className="col-lg-4">
                                    <h2 className="heading_text mb-0">
                                        Bersama DELTA, #siapjadiabdinegara!
                                    </h2>
                                </div>
                                <div className="col-lg-6">
                                    <p className="heading_description mb-0">
                                        DELTA merupakan bimbingan belajar khusus Sekolah Kedinasan, CPNS, AKMIL dan AKPOL yang Terbukti meloloskan ratusan siswanya ke berbagai sekolah Kedinasan!. Kami hadir untuk membantu siswa-siswi yang ingin mewujudkan cita-citanya menjadi abdi negara. Persiapkan dirimu bersama DELTA!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PolicySection />
                <FeaturesSection />
                <Tentor />
                <WhyUs />
                <CtaSection />
            </main>
            <Footer />
            <WhatsappButton/>
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default AboutUsPage;
