import React from 'react';
import blogs from '../../api/blogs';
import Bg from '../../images/shapes/bg_pattern_1.svg';

const BlogSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll to the top
        });
    };

    // Handle WhatsApp link click
    const handleWhatsAppClick = (whatsappUrl) => {
        // Redirect user to the specified WhatsApp URL
        window.location.href = whatsappUrl;
    };

    return (
        <section className="blog_section section_space bg-light" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="heading_block text-center">
                    <h2 className="heading_text mb-0">
                        Kunjungi Cabang Kami!
                    </h2>
                </div>

                <div className="row">
                    {blogs.slice(0, 4).map((blog, Bitem) => (
                        <div className="col-lg-3" key={Bitem}>
                            <div className="blog_post_block">
                                <div className="blog_post_image">
                                    {/* Gunakan <a> untuk mengarahkan ke Google Maps */}
                                    <a
                                        href={blog.maps}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn_unfill"
                                        onClick={ClickHandler}
                                    >
                                        <img src={blog.screens} alt={`${blog.title}`} />
                                    </a>
                                </div>
                                <div className="blog_post_content">
                                    <h2 className="blog_post_title" style={{ marginTop: '-5px', marginBottom: '10px' }}>
                                        {/* Judul juga dapat mengarah ke Google Maps */}
                                        <a
                                            href={blog.maps}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn_unfill"
                                            onClick={ClickHandler}
                                        >
                                            {blog.title}
                                        </a>
                                    </h2>
                                    <h6 style={{ marginTop: '2px' }}>{blog.alamat}</h6>

                                    {/* Kontak WhatsApp dan Instagram */}
                                    <div className="contact_info">
                                        <div className="contact_item">
                                            {/* When user clicks on WhatsApp, redirect to the URL */}
                                            <button
                                                className="btn_unfill contact_icon"
                                                onClick={() => handleWhatsAppClick(blog.whatsappUrl)}// Use the blog's whatsappurl here
                                            >
                                                <i className="fa-brands fa-whatsapp"></i>
                                                <span>{blog.whatsapp}</span>
                                            </button>
                                        </div>
                                        <div className="contact_item" style={{ marginBottom: '15px' }}>
                                            <i className="fa-brands fa-instagram contact_icon"></i>
                                            <span>{blog.instagram}</span>
                                        </div>
                                    </div>

                                    {/* Tombol untuk membuka Google Maps */}
                                    <a
                                        href={blog.maps}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn_unfill"
                                        onClick={ClickHandler}
                                    >
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                        <span className="btn_label">Google Maps</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default BlogSection;
