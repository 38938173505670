
import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="xb-faq-content">
                            <div className="sec-title mb-125">
                                <h2 className="mb-30 wow skewIn"><br></br><br></br><br></br>Pertanyaan yang <br /> <span> Sering Ditanyakan</span></h2>
                                <p>Apabila masih ada pertanyaan yuk tanyakan ke admin kami,<br></br> Klik tombol WhatsApp di pojok kanan bawah ya!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="xb-faq">
                            <Accordion open={open} toggle={toggle} className='accordion_box'>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="1" className='acc-btn'>Kapan Jadwal di DELTA Dimulai ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1" className='acc_body'>
                                        <div className="content">
                                            <p>Jadwal les di delta dimulai bulan Agustus-Tes, perkiraan tes Juni. 
                                                <br />
                                                    Pembelajaran terbagi menjadi 3 kategori :</p>
                                                <ul>
                                                    <li>Kelas Materi (Agustus - Desember)</li>
                                                    <li>⁠Kelas Drilling (Januari - April)</li>
                                                    <li>⁠Kelas Intensif (Mei - Tes)</li>
                                                </ul>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="2" className='acc-btn'>Apakah biaya bimbel di DELTA bisa dicicil ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2" className='acc_body'>
                                        <div className="content">
                                            <p>Bisa banget dong, sobat delta bisa melakukan mekanisme cicilan, yuk segera daftar dan konsultasikan dengan admin kami.</p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="3" className='acc-btn'>Apakah tersedia program bimbel Online di DELTA ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3" className='acc_body'>
                                        <div className="content">
                                            <p>Kami juga membuka kelas online buat kamu calon Taruna Taruni seluruh Indonesia dengan Program yg siap jadiin kamu abdi negara dengan biaya yg tentunya terjangkau.</p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="4" className='acc-btn'>Bagaimana mekanisme sistem jaminan di DELTA ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4" className='acc_body'>
                                        <div className="content">
                                            <p>Program jaminan kami tersedia di Program Super Exclusive dengan jaminan FREE Bimbel 1 Tahun senilai 15jt++ jika kamu gagal lolos di Tahun pertama pendaftaran Sekolah Kedinasan, Akmil, Akpol</p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="5" className='acc-btn'>Apa perbedaan antara yang bimbel di DELTA dan yang tidak ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5" className='acc_body'>
                                        <div className="content">
                                            <p>Tentunya beda banget bersama Delta Bimbel Kedinasan, kamu akan dimbimbing dengan Tentor Profesional dan Master di bidangnya. Total pertemuan pembelajaran hingga 300++ dan total drill soal materi dan Tryout hingga 10.000 soal. Setiap Program yg diambil juga sudah termasuk FREE konsultasi dan tambahan UNLIMITED sampai kamu tes. Program Delta juga termasuk pendampingan Latihan Kesemamtaan Jasmani, Tes Psikologi dan Wawancara. Kami juga akan melakukan pendampingan ketika kamu Tes SKB lohh. 
                                            </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="6" className='acc-btn'>Apakah bimbel di DELTA dijamin lolos kedinasan ?
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="6" className='acc_body'>
                                        <div className="content">
                                            <p>Kami menjamin kalian akan mendapatkan pelatihan dan bimbingan terbaik, kami juga telah meloloskan 280+ siswa di berbagai Sekolah Kedinasan di Indonesia. Jadi tunggu apalagi yuk buruan join dan buktikan sendiri. 
                                            </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default FaqSection;
