import { FloatingWhatsApp } from '@carlos8a/react-whatsapp-floating-button';
import csIcon from '../../images/icons/cs.svg';

const App = () => {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber='6289676611116' // Required
        accountName='Konsultan Kedinasan' // Optional
        avatar={csIcon} // Pass the image URL directly
        initialMessageByServer='Selamat Datang di Bimbel Kedinasan Delta. Ada yang bisa kami bantu?' // Optional
        initialMessageByClient='Halo kak. Saya mendapat informasi dari web DELTA, bisa dijelaskan mengenai Sekolah Kedinasan dan Program DELTA ?' // Optional
        statusMessage='Available' // Optional
        startChatText='Mulai Chat' // Optional
        tooltipText='Konsultasi Sekdin GRATIS!' // Optional
        allowEsc={true} // Optional
        // Explore all available props below
      />
    </div>
  );
};

export default App;
